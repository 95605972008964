import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Test from "./Container/Test/Test";
const Footer = lazy(() => import('./Container/Components/Footer/Footer'))
const Navbar = lazy(() => import('./Container/Navbar/Navbar'))
const Errorpage = lazy(() => import('./Container/Errorpage/Errorpage'));
const Mainpage = lazy(() => import('./Container/Mainpage/Mainpage'));
const Timeline = lazy(() => import('./Container/Components/Timeline/Timeline'))
const AboutPage = lazy(() => import('./Container/Pages/AboutPage'))
const Services = lazy(() => import('./Container/Pages/Services'))
const ProductDetail = lazy(() => import('./Container/Pages/ProductDetail'))
const FAQ = lazy(() => import('./Container/Pages/FAQ'))
const Careers = lazy(() => import('./Container/Pages/Careers'))
const Blogs = lazy(() => import('./Container/Pages/Blogs'))
const Sustainability = lazy(() => import('./Container/Pages/Sustainability'))
const Craftmanship = lazy(() => import('./Container/Pages/Craftmanship'))
const MaasaiWomenProject = lazy(() => import('./Container/Pages/MaasaiWomenProject'))
const ContactPage = lazy(() => import('./Container/Pages/ContactPage'))
const PrivacyPolicy = lazy(() => import('./Container/Pages/PrivacyPolicy'))
const CookiePolicy = lazy(() => import('./Container/Pages/CookiePolicy'))
const TermsAndCondition = lazy(() => import('./Container/Pages/TermsAndCondition'))
const BookAppointment = lazy(() => import('./Container/Pages/BookAppointment'))
const OfficesAndStores = lazy(() => import('./Container/Pages/OfficesAndStores'))
const Sitemap = lazy(() => import('./Container/Pages/Sitemap'))
const HighJewellery = lazy(() => import('./Container/HighJewellery/HighJewellery'))
const GemExperienceIcons = lazy(() => import('./Container/HighJewellery/GemExperienceIcons'))
const Theia = lazy(() => import('./Container/HighJewellery/Theia'))
const ChapterOne = lazy(() => import('./Container/HighJewellery/ChapterOne'))
const ChapterTwo = lazy(() => import('./Container/HighJewellery/ChapterTwo'))
const ChapterThree = lazy(() => import('./Container/HighJewellery/ChapterThree'))
const NatureSerenity = lazy(() => import('./Container/HighJewellery/NatureSerenity/NatureSerenity'))
const ForestCrown = lazy(() => import('./Container/HighJewellery/NatureSerenity/ForestCrown'))
const RoyalStarling = lazy(() => import('./Container/HighJewellery/NatureSerenity/ForestCrown/RoyalStarling'))
const Helix = lazy(() => import('./Container/HighJewellery/NatureSerenity/ForestCrown/Helix'))
const Mediterranea = lazy(() => import('./Container/HighJewellery/NatureSerenity/Mediterranea'))
const Meditrove = lazy(() => import('./Container/HighJewellery/NatureSerenity/Mediterranea/Meditrove'))
const Dewfall = lazy(() => import('./Container/HighJewellery/NatureSerenity/Mediterranea/Dewfall'))
const Eternalwave = lazy(() => import('./Container/HighJewellery/NatureSerenity/Mediterranea/Eternalwave'))
const RoseDeMarin = lazy(() => import('./Container/HighJewellery/NatureSerenity/Mediterranea/RoseDeMarin'))
const WorldOfPreciousness = lazy(() => import('./Container/WorldOfPreciousness/WorldOfPreciousness'))
const Tanzanite = lazy(() => import('./Container/WorldOfPreciousness/Tanzanite'))
const Spinel = lazy(() => import('./Container/WorldOfPreciousness/Spinel'))
const Tsavorite = lazy(() => import('./Container/WorldOfPreciousness/Tsavorite'))
const Rhodolite = lazy(() => import('./Container/WorldOfPreciousness/Rhodolite'))
const Malayagarnet = lazy(() => import('./Container/WorldOfPreciousness/Malayagarnet'))
const FineJewellery = lazy(() => import('./Container/FineJewellery/FineJewellery'))
const FinejewelleryLookbook = lazy(() => import('./Container/FineJewellery/FinejewelleryLookbook'))
const CategoryPage = lazy(() => import('./Container/FineJewellery/Category/CategoryPage'))
const GemstonePage = lazy(() => import('./Container/FineJewellery/GemStone/GemstonePage'))
const SubGemStone = lazy(() => import('./Container/FineJewellery/GemStone/SubGemStone'))
const SubCategory = lazy(() => import('./Container/FineJewellery/Category/SubCategory'))
const SubCollection = lazy(() => import('./Container/FineJewellery/Collection/SubCollection'))
const EngagementBridal = lazy(() => import('./Container/EngagementBridal/EngagementBridal'))
const Engagement = lazy(() => import('./Container/EngagementBridal/Engagement'))
const Wedding = lazy(() => import('./Container/EngagementBridal/Wedding'))
const Gifts = lazy(() => import('./Container/Gifts/Gifts'))
const WeddingGifts = lazy(() => import('./Container/Gifts/WeddingGifts'))
const ChristeningGifts = lazy(() => import('./Container/Gifts/ChristeningGifts'))
const BirthdayGIfts = lazy(() => import('./Container/Gifts/BirthdayGIfts'))


export const Approutes = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Navbar />
          <Routes>
            <Route element={<Errorpage />} path="*" />
            <Route element={<Mainpage />} path="/" />
            {/* <Route element={<Test />} path="/test" /> */}

            <Route element={<Timeline />} path="/timeline" />
            <Route element={<AboutPage />} path="/about" />
            <Route element={<Services />} path="/services" />
            <Route element={<ProductDetail />} path="/pd/:id" />

            <Route element={<FAQ />} path="/faq" />
            <Route element={<Careers />} path="/career" />
            <Route element={<Blogs />} path="/blogs" />
            <Route element={<Sustainability />} path="/sustainability" />
            <Route element={<Craftmanship />} path="/craftmanship" />
            <Route element={<MaasaiWomenProject />} path="/maasai-women-project" />
            <Route element={<ContactPage />} path="/contactus" />
            <Route element={<PrivacyPolicy />} path="/privacy-policy" />
            <Route element={<CookiePolicy />} path="/cookies-policy" />
            <Route element={<TermsAndCondition />} path="/terms-and-conditions" />
            <Route element={<BookAppointment />} path="/book-an-appointment" />
            <Route element={<OfficesAndStores />} path="/gem-experience-office" />
            <Route element={<Sitemap />} path="/sitemap" />


            <Route element={<HighJewellery />} path="/highjewellery" />
            <Route element={<GemExperienceIcons />} path="/gem-experience-icons" />
            <Route element={<Theia />} path="/theia" />
            <Route element={<ChapterOne />} path="/the-goddess-with-passion-for-tanzanite" />
            <Route element={<ChapterTwo />} path="/the-blood-red" />
            <Route element={<ChapterThree />} path="/a-revolution-of-two-worlds" />
            <Route element={<NatureSerenity />} path="/nature-serenity" />
            <Route element={<ForestCrown />} path="/nature-serenity/forest-crown" />
            <Route element={<RoyalStarling />} path="/nature-serenity/royal-starling" />
            <Route element={<Helix />} path="/nature-serenity/helix" />
            <Route element={<Mediterranea />} path="/nature-serenity/mediterranea" />
            <Route element={<Meditrove />} path="/nature-serenity/meditrove" />
            <Route element={<Dewfall />} path="/nature-serenity/dewfall" />
            <Route element={<Eternalwave />} path="/nature-serenity/eternal-wave" />
            <Route element={<RoseDeMarin />} path="/nature-serenity/rose-de-marin" />

            <Route element={<WorldOfPreciousness />} path="/world-of-preciousness" />
            <Route element={<Tanzanite />} path="/wop-tanzanite" />
            <Route element={<Spinel />} path="/wop-Spinel" />
            <Route element={<Tsavorite />} path="/wop-tsavorite" />
            <Route element={<Rhodolite />} path="/wop-rhodolite" />
            <Route element={<Malayagarnet />} path="/wop-malaya-garnet" />

            <Route element={<FineJewellery />} path="/store/collection" />
            <Route element={<FinejewelleryLookbook />} path="/lookbook" />
            <Route element={<CategoryPage />} path="/store/category" />
            <Route element={<GemstonePage />} path="/store/gem-stone" />
            <Route element={<SubGemStone />} path="/store/gem-stone/:subgemstone" />
            <Route element={<SubCategory />} path="/store/category/:subcategory" />
            <Route element={<SubCollection />} path="/store/collection/:subcollection" />

            <Route element={<EngagementBridal />} path="/store/engagement-bridal" />
            <Route element={<Engagement />} path="/store/engagement-bridal/engagement" />
            <Route element={<Wedding />} path="/store/engagement-bridal/wedding" />
            <Route element={<Gifts />} path="/store/gifts" />
            <Route element={<WeddingGifts />} path="/store/gifts/wedding-gifts" />
            <Route element={<ChristeningGifts />} path="/store/gifts/christening-gifts" />
            <Route element={<BirthdayGIfts />} path="/store/gifts/birthday-gifts" />

          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  )
}
