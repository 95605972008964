import { httpverbs } from "../constants";
import http from "../services/http";
import * as endpoints from './endpoints';

export const getOfficesApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.officesUrl
    });
}
export const getStoresApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.storesUrl
    });
}
// Get All Products Api (fine jewellery page or By collection)
export const getProductsApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.productsUrl
    });
}
// Get ALl Products of Engagemwnt & Bridal section Api 
export const GetEngagementProductsApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.engagementBridalUrl
    });
}
// Get All products of Gifts Section Api 
export const GetGiftsProductsApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.giftsUrl
    })
}
// get all products of Category 
export const GetCategoryProductsApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.categoryUrl
    })
}
// get all products of gemstones 
export const GetGemstoneProductsApi = () => {
    return http({
        method: httpverbs.Get,
        url: endpoints.gemStoneUrl
    })
}
// get Collection by Category
export const GetCollectionProductByValueApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.collectionUrl}/${value}`
    })
}
// get Category by Category 
export const GetCategoryProductByValueApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.categoryUrl}/${value}`
    })
}
// get GEmstone by Category 
export const GetGemstoneProductByValueApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.gemStoneUrl}/${value}`
    })
}
// Get Gifts Products by Category 
export const GetGiftsProductByValueApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.giftsUrl}/${value}`
    })
}
// get Engagement Products By Category
export const GetEngagementProductByValueApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.engagementBridalUrl}/${value}`
    })
}
// Search Api 
export const getSearchApi = (value) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.searchUrl}/${value}`
    });
}
// Get an Single Product Api 
export const getProductById = (productId) => {
    return http({
        method: httpverbs.Get,
        url: `${endpoints.productsUrl}/${productId}`
    });
}





