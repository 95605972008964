import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Approutes } from './Approutes';
import { DataProvider } from './DataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <DataProvider> */}
    <div>
      <div className='ShowMessage text-2xl p-4'>Sorry for inconvenience 🙏<br />For now this site does support Screen size less than 300px </div>
      <div className='main-content'><Approutes /></div>
    </div>
    {/* </DataProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
